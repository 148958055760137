.rb.nw{
    max-width: 1140px;
    width: 100%;
    border-top: 1px solid rgba(0,0,0,.1);
}

.footer-main-div{
    width: 100%;
    height: auto;
    margin: auto;
    padding: 20px 0px;

}

.ft{
    padding-top: 20px;
    padding-bottom: 10px;
}


.footer-social-icons{
    width: 100%;
    height: auto;
    margin: auto;
}

.footer-social-icons ul{
    margin: 0px;
    padding: 0px;
    text-align: center;
}


.footer-social-icons ul li{
    display: inline-block;
    width: 50px;
    height: 50px;
    margin: 0px 10px;
    border-radius: 100%;
    background: rgba(0, 0, 0, 0.35);
    transition: all ease 0.5s;
}


.footer-social-icons ul li a{
    color: #272727;
    font-size: 25px;
    display: block;
    text-decoration: none;
    border-radius: 100%;
    transition: 0.6s;
    transition: all ease 0.5s;
    /* box-shadow: 0 5px 4px rgba(100,100,100,.5); */

}

.footer-social-icons ul li a i{
    line-height: 50px;
    color: white;
}

.footer-social-icons ul li:hover{
    transform: translateY(-8px);
}



.footer-social-icons ul li:nth-child(1) a:hover{
    background-color:  #3b5998;
    top: 60px;
    color: #fff;
}

.footer-social-icons ul li:nth-child(2) a:hover{
    background-color:  #55acee;
    top: 60px;
    color: #fff;
}


.footer-social-icons ul li:nth-child(3) a:hover{
    background-color:   #db4a39;
    top: 60px;
    color: #fff;
}

.footer-social-icons ul li:nth-child(4) a:hover{
    background-color:   #c4302b;
    top: 60px;
    color: #fff;
}



.footer-menu-one{
    width: 100%;
    height: auto;
    margin: auto;
    margin-top: 20px;
}

.footer-menu-one ul{
    margin: 0px;
    padding: 0px;
    text-align: center;
}

.footer-menu-one ul li{
    display: inline-block;
    margin: 0px 15px;
}

.footer-menu-one ul li a{
    font-family: arial;
    font-size: 15px;
    font-weight: 600px;
    color: #ffffff;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-decoration: none;
}
.footer-menu-one ul li a:hover{
    /* color: #607D8B; */
    cursor: pointer;
}


.footer-bottom{
    width: 100%;
    height: auto;
    margin: auto;


}


.footer-bottom p{
    font-family: arial;
    font-size: 14px;
    text-align: center;
    color: #000;
    font-weight: 600;
    letter-spacing: 1px;
}


.footer-bottom p a{
    color: #000;
    text-decoration: none;

}

.footer-bottom p a:hover{
    color: #607D8B;
}

