.asset-header{
      display: flex;
    justify-content: space-around;
        margin-top: 40px;
}
.asset-header a {
  font-size: 0.8rem;
  min-width: 115px;
}
.w20pr {
  width: 20%;
}
