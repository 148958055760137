.header-container {
  width: 100%;
  height: auto;
}
.header-link {
  font-size: 12px;
  margin: 12px;
}
.ny.reveal-from-bottom {
  width: 100%;
  height: 103px;
  text-align: center;
}

.switch {
  position: relative;
  overflow: hidden;
  width: 3.75rem;
  height: 2rem;
  margin-left: 10px;
}

.switch input {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  opacity: 0;
}

.switch label {
  cursor: pointer;
}

.background {
  z-index: 1;
  position: absolute;
  width: 3.75rem;
  height: 2rem;
  border-radius: 2.5rem;
  border: 0.25rem solid #202020;
  background: linear-gradient(to right, #484848 0%, #202020 100%);
  transition: all 0.3s;
}

.fill {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 2rem;
  left: 0;
  background: #484848;
  transition: 0.75s all ease;
}

.switch input:checked ~ .fill {
  background: #e9f8fd;
}

.stars1,
.stars2 {
  position: absolute;
  height: 0.2rem;
  width: 0.2rem;
  background: #ffffff;
  border-radius: 50%;
  transition: 0.3s all ease;
}

.stars1 {
  top: 6px;
  right: 23px;
}

.stars2 {
  top: 40px;
  right: 48px;
}

.stars1:after,
.stars1:before,
.stars2:after,
.stars2:before {
  position: absolute;
  content: "";
  display: block;
  height: 0.25rem;
  width: 0.25rem;
  background: #ffffff;
  border-radius: 50%;
  transition: 0.2s all ease;
}

.stars1:after {
  top: 8px;
  right: 20px;
}

.stars1:before {
  top: 18px;
  right: -12px;
}

.stars2:after {
  top: -8px;
  right: -16px;
}

.stars2:before {
  top: 6px;
  right: -26px;
}

.sun-moon {
  z-index: 2;
  position: absolute;
  left: 0;
  display: inline-block;
  height: 1.5rem;
  width: 1.5rem;
  margin: 0.25rem;
  background: #fffdf2;
  border-radius: 50%;
  transition: all 0.5s ease;

  /* Default to Moon */
  border: 0.25rem solid #dee2c6;
}

.sun-moon .dots {
  position: absolute;
  top: 1px;
  left: 12px;
  height: 0.5rem;
  width: 0.5rem;
  background: #efeedb;
  border: 0.25rem solid #dee2c6;
  border-radius: 50%;
  transition: 0.4s all ease;
}

.sun-moon .dots:after,
.sun-moon .dots:before {
  position: absolute;
  content: "";
  display: block;
  height: 0.25rem;
  width: 0.25rem;
  background: #efeedb;
  border: 0.25rem solid #dee2c6;
  border-radius: 50%;
  transition: 0.4s all ease;
}

.sun-moon .dots:after {
  top: -5px;
  left: -13px;
}

.sun-moon .dots:before {
  top: 6px;
  left: -10px;
}

/* Transition to Sun */

.switch input:checked ~ .sun-moon {
  left: calc(100% - 2rem);
  background: #f5ec59;
  border-color: #e7c65c;
  transform: rotate(-25deg);
}

.switch input:checked ~ .sun-moon .dots,
.switch input:checked ~ .sun-moon .dots:after,
.switch input:checked ~ .sun-moon .dots:before {
  background: #ffffff;
  border-color: #ffffff;
}

.switch input:checked ~ .sun-moon .dots {
  height: 1.5rem;
  width: 1.5rem;
  top: 0px;
  left: -20px;
  transform: rotate(25deg);
}

.switch input:checked ~ .sun-moon .dots:after {
  height: 0.65rem;
  width: 0.65rem;
  top: 2px;
  left: -12px;
}

.switch input:checked ~ .sun-moon .dots:before {
  height: 0.2rem;
  width: 0.2rem;
  top: 6px;
  left: 14px;
}

.switch input:checked ~ .background .stars1,
.switch input:checked ~ .background .stars2 {
  opacity: 0;
  transform: translateY(2rem);
}

.switch input:checked ~ .background {
  border: 0.25rem solid #78c1d5;
  background: linear-gradient(to right, #78c1d5 0%, #bbe7f5 100%);
}
