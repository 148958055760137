.fixed140{
    width: 140px;
    text-align: center;
}
.withdraw-balance-button {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.avaible-balance-button  {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.button span {
  font-size: 0.7rem;  
}
.smalltext {
  font-size: 0.7rem;  
}
