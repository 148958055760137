.modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.8);
  display: none;
  justify-content: center;
  align-items: center;
}

.asset .activeModal {
  display: flex;
  pointer-events: visible;
}

.asset .activeModal div {
  pointer-events: visible;
}

.modal-content {
  background-color: rgba(245, 250, 255, 1);
  width: 440px;
  min-width: 320px;
  min-height: 50%;
  border-radius: 15px;
  padding: 15px;
  position: relative;
}

.modal-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal-header .modal-logo {
  margin-top: 10px;
  margin-bottom: 10px;
}

.modal-header .modal-logo img {
  max-width: 40px;
  height: auto;
}

.modal .modal-header h3 {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  line-height: 27px;
  color: #1e1e1e;
}

.modal .modal-balance {
  margin-top: 15px;
}
.modal .modal-balance h6 {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.15008px;
  color: #1e1e1e;
}

.modal .modal-balance .modal-price {
  margin-top: 5px;
  text-align: center;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 20px;
  color: #1e1e1e;
}

.modal .modal-balance .modal-price .modal-changed {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 17px;
  letter-spacing: 0.123px;
  color: #7a8386;
}

.modal-menu {
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
}

.modal-menu a {
  width: 50%;
  text-align: center;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 17px;
  letter-spacing: 0.123px;
  color: #7a8386;
  padding-bottom: 15px;
  margin-left: -15px;
  margin-right: -15px;
  cursor: pointer;
}

.modal-menu a.active,
.darkTheme .modal-menu a.active {
  color: #0d449a;
  border-bottom: 3px solid #0d449a;
}

.modal-form {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.modal-form input {
  background: #ffffff;
  border: 1px solid #b4b9bb;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 10px;
  margin-top: 20px;
}

.modal-form label {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 20px;
  letter-spacing: 0.39996px;
  color: #1e1e1e;
  margin-bottom: -15px;
}

.modal-form input::placeholder {
  text-align: end;
}

.modal-form .centr {
  text-align: center;
}

.modal-progress {
  margin-top: 20px;
}

.modal-progress-info {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 20px;
  letter-spacing: 0.39996px;
  color: #7a8386;
  background: #f1f1f1;
  border-radius: 7px;
  padding: 2px 10px;
}
.modal-progress-bar {
  margin-top: 10px;
  display: flex;
  align-content: center;
  justify-content: space-between;
}

.modal-progress span {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.39996px;
  color: #7a8386;
}

.step {
  width: 30px;
  height: 15px;
  background: #e1e3e4;
  border: 2px solid #e1e3e4;
  box-sizing: border-box;
}

.line {
  width: inherit;
  height: 3px;
  background: #e1e3e4;
  border-radius: 4px;
}

.modal-main-progress {
  width: 75%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.line.active,
.step.active {
  background: #0d449a;
  border-color: #0d449a;
}

.modal-info {
  margin-top: 30px;
  border: 1px solid #bf0a32;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 5px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.modal-info {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 14px;
  letter-spacing: 0.10318px;
  color: #bf0a32;
}

.modal-button {
  background: #e6e6e6;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
  margin-top: 20px;
  padding: 20px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 29px;
  text-align: center;
  color: rgba(0, 0, 0, 0.26);
}

.modal-footer {
  display: flex;
  justify-content: center;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 20px;
  letter-spacing: 0.39996px;
  color: #7a8386;
  margin-top: 20px;
}

.close {
  position: absolute;
  font-size: 23px;
  right: 20px;
  top: 10px;
  cursor: pointer;
  color: #1b2336;
}

.darkTheme .modal-content,
.darkTheme .modal-form input {
  background-color: #1b2336;
}

.darkTheme .modal {
  background-color: rgba(0, 0, 0, 0.8);
}

.darkTheme .modal .modal-header h3,
.darkTheme .modal .modal-balance h6,
.darkTheme .modal .modal-balance .modal-price,
.darkTheme .modal-menu a,
.darkTheme .modal-form input,
.darkTheme .modal-form input::placeholder,
.darkTheme .modal-form label,
.darkTheme .close {
  color: #fff;
  border-color: #fff;
}

.darkTheme .modal-form input {
  border: 1px solid #fff;
}

@media screen and (max-width: 526px) {
  .modal-content {
    width: auto;
  }
  .modal-menu {
    margin-top: 10px;
  }
  .modal-menu a {
    padding-bottom: 10px;
  }
  .modal-main-progress {
    width: 70%;
  }
  .modal-form input {
    margin-top: 15px;
  }
  .modal-button {
    padding: 5px;
    margin-top: 10px;
  }
  .modal-header {
    text-align: center;
  }
  .modal-form {
    margin-top: 5px;
  }
  .modal-info {
    margin-top: 10px;
  }
  .modal-form input {
    padding: 5px 10px;
  }
  .modal-header .modal-logo {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .modal .modal-balance {
    margin-top: 5px;
  }
  .modal-footer {
    font-size: 9px;
    margin-top: 10px;
  }
}
